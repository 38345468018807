<template>
  <app-module-view>
    <template slot="body">
      <Modal
        v-if="showVirtualSwitchModal"
        @close="toggleVirtualSwitchModal('close')"
        modal-size="modal-md"
      >
        <!-- Modal Body -->
        <template slot="body">
          <p v-if="!rubric.isVirtualized">{{ $t('rubric.enableVirtualizationMsg') }}</p>
          <p v-else>{{ $t('rubric.disableVirtualizationMsg') }}</p>
        </template>

        <!-- Modal Footer -->
        <template slot="footer">
          <button
            type="button"
            class="btn btn-inverse router-link-active"
            @click="toggleVirtualSwitchModal('close')"
          >
            {{ $t('modal.delete_modal_cancel_btn') }}
          </button>
          <button
            type="button"
            :class="`btn ${rubric.isVirtualized ? 'btn-danger' : 'btn-info'}`"
            @click="toggleVirtual"
          >
            <i class="fa fa-layer-group " />
            {{ $t(`rubric.${rubric.isVirtualized ? 'un' : ''}virtualized`) }}
          </button>
        </template>
      </Modal>
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-6 d-flex align-items-end">
            <div v-if="vlm && rubric.isVirtualized" class="d-flex align-items-center with-gap">
              {{ $t('rubric.isVirtualized') }}
              <IconCheck class="check-icon" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="text-right m-r-5">
              <button
                v-if="isSaveButtonVisible"
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <button
                v-if="isVirtualizeButtonVisible"
                type="button"
                :class="`btn ${rubric.isVirtualized ? 'btn-danger' : 'btn-info'}`"
                @click="toggleVirtualSwitchModal('open')"
              >
                <i class="fa fa-layer-group " />
                {{ $t(`rubric.${rubric.isVirtualized ? 'un' : ''}virtualized`) }}
              </button>
              <app-button-delete v-if="isDeleteButtonVisible" @deleteRecord="deleteRubric" />
              <app-button-close route-name="rubric_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-6">
                    <MultiSelect
                      v-model="productsForRubric"
                      :options="edonProducts"
                      :error="$v.rubric.dataHubProductsIds.$error"
                      label="title"
                      track-by="id"
                      :label-desc="productLabel"
                      id="quiz_categories"
                    />
                    <input type="hidden" v-model="rubric.id">
                    <app-select
                      v-model="rubric.site"
                      :options="sites"
                      id="rubric_site"
                      :disabled="isEdit"
                      :error="$v.rubric.site.$error"
                      :label="`${$t('rubric.site')}*`"
                    >
                    </app-select>

                    <app-input
                      v-model="rubric.title"
                      @blur="$v.rubric.title.$touch()"
                      :error="$v.rubric.title.$error"
                      id="rubric_title"
                      :label="`${$t('rubric.title')}*`"
                    >
                    </app-input>

                    <app-input
                      v-model="rubric.textForSlug"
                      @blur="$v.rubric.textForSlug.$touch()"
                      :error="$v.rubric.textForSlug.$error"
                      :disabled="isEdit"
                      id="rubric_slug"
                      :label="`${$t('rubric.textForSlug')}*`"
                    >
                    </app-input>

                    <app-input
                      v-if="isEdit"
                      v-model="rubric.slug"
                      @blur="$v.rubric.slug.$touch()"
                      :error="$v.rubric.slug.$error"
                      :disabled="isEdit"
                      id="rubric_slug"
                      :label="`${$t('rubric.slug')}*`"
                    >
                    </app-input>

                    <div class="form-group">
                      <app-select
                        v-model="rubric.parent"
                        :options="rubricsBySite"
                        :disabled="isEdit"
                        id="rubric_parent"
                        :label="`${$t('rubric.parent')}*`"
                      />
                    </div>

                    <app-input
                      v-model="rubric.sorting"
                      @blur="$v.rubric.sorting.$touch()"
                      :error="$v.rubric.sorting.$error"
                      id="rubric_meta_sorting"
                      type="number"
                      :min="0"
                      :label="$t('rubric.sorting')"
                    >
                    </app-input>

                    <app-input
                      v-model="rubric.meta.title"
                      @blur="$v.rubric.meta.title.$touch()"
                      :error="$v.rubric.meta.title.$error"
                      id="rubric_meta_title"
                      :label="$t('rubric.meta_title')"
                    >
                    </app-input>

                    <app-input
                      v-model="rubric.meta.description"
                      @blur="$v.rubric.meta.description.$touch()"
                      :error="$v.rubric.meta.description.$error"
                      id="rubric_meta_description"
                      :label="$t('rubric.meta_description')"
                    >
                    </app-input>

                    <app-input
                      v-model="rubric.meta.keywords"
                      @blur="$v.rubric.meta.keywords.$touch()"
                      :error="$v.rubric.meta.keywords.$error"
                      id="rubric_meta_keywords"
                      :label="$t('rubric.meta_keywords')"
                    >
                    </app-input>
                    <div v-if="vlm" class="title-text">
                      {{ $t('rubric.description') }}
                    </div>
                    <RichTextEditor
                      v-if="vlm"
                      v-model="rubric.description"
                      :other-options="richTextEditorConfig"
                      id="rubric_description"
                    />
                  </div>
                  <div class="col-lg-6">
                    <app-input
                      v-model="rubric.setting.advertCategory"
                      @blur="$v.rubric.setting.advertCategory.$touch()"
                      :error="$v.rubric.setting.advertCategory.$error"
                      id="rubric_setting_advertCategory"
                      :label="$t('rubric.setting_advertCategory')"
                    >
                    </app-input>
                    <app-input
                      v-model="rubric.setting.advertSection"
                      @blur="$v.rubric.setting.advertSection.$touch()"
                      :error="$v.rubric.setting.advertSection.$error"
                      id="rubric_setting_advertSection"
                      :label="$t('rubric.setting_advertSection')"
                    >
                    </app-input>

                    <app-input
                      v-model="rubric.setting.chartbeatSection"
                      @blur="$v.rubric.setting.chartbeatSection.$touch()"
                      :error="$v.rubric.setting.chartbeatSection.$error"
                      id="rubric_setting_chartbeatSection"
                      :label="$t('rubric.setting_chartbeatSection')"
                    >
                    </app-input>

                    <app-input
                      v-model="rubric.setting.gemiusId"
                      @blur="$v.rubric.setting.gemiusId.$touch()"
                      :error="$v.rubric.setting.gemiusId.$error"
                      id="rubric_setting_gemiusId"
                      :label="$t('rubric.setting_gemiusId')"
                    >
                    </app-input>

                    <app-input
                      v-if="!vlm"
                      v-model="rubric.sportTable.seasonId"
                      @blur="$v.rubric.sportTable.seasonId.$touch()"
                      :error="$v.rubric.sportTable.seasonId.$error"
                      id="rubric_sportTable_seasonId"
                      type="number"
                      :min="0"
                      :label="$t('rubric.sportTable_seasonId')"
                    >
                    </app-input>

                    <app-input
                      v-if="!vlm"
                      v-model="rubric.sportTable.activeTab"
                      @blur="$v.rubric.sportTable.activeTab.$touch()"
                      :error="$v.rubric.sportTable.activeTab.$error"
                      id="rubric_sportTable_activeTab"
                      type="number"
                      :min="1"
                      :label="$t('rubric.sportTable_activeTab')"
                    >
                    </app-input>

                    <div v-if="vlm" class="form-group">
                      <label>
                        {{ $t('rubric.iptcCategory') }}
                      </label>
                      <MultiSelect
                        :value="rubric.iptcCategories ? rubric.iptcCategories.map(categoryId => (iptcCategories.find(c => c.id === categoryId))): []"
                        @input="(value) => rubric.iptcCategories = value ? value.map(v => v.id) : []"
                        :options="iptcCategories"
                        label="name"
                        track-by="id"
                        id="rubric_iptcCategory"
                      />
                    </div>

                    <app-checkbox
                      v-model="rubric.setting.prRubric"
                      id="rubric_setting_prRubric"
                      :label="$t('rubric.setting_prRubric')"
                    >
                    </app-checkbox>

                    <app-checkbox
                      v-model="rubric.setting.special"
                      id="rubric_setting_special"
                      :label="$t('rubric.setting_special')"
                    >
                    </app-checkbox>

                    <app-checkbox
                      v-model="rubric.rubricWithoutArticles"
                      id="rubric_rubricWithoutArticles"
                      :label="$t('rubric.rubric_without_articles')"
                    >
                    </app-checkbox>

                    <app-checkbox
                      v-model="rubric.hiddenInMenu"
                      id="rubric_hiddenInMenu"
                      :label="$t('rubric.hiddenInMenu')"
                    >
                    </app-checkbox>
                    <template v-if="vlm">
                      <app-checkbox
                        v-model="rubric.isArticleToppingEnabled"
                        id="rubric_isArticleToppingEnabled"
                        :label="$t('rubric.isArticleToppingEnabled')"
                      />
                      <div class="title-text">
                        {{ $t('rubric.heroImage') }}
                      </div>
                      <ModuleFormPhotoBox
                        :image="rubric.heroImage"
                        :media-usage-type="mediaUsageTypes.hero"
                        hide-separator
                        @set-media="setHeroImage"
                        @remove-media="removeHeroImage"
                      />
                      <div class="title-text">
                        {{ $t('rubric.miniatureImage') }}
                      </div>
                      <ModuleFormPhotoBox
                        :image="rubric.miniatureImage"
                        :media-usage-type="mediaUsageTypes.miniature"
                        hide-separator
                        @set-media="setMiniatureImage"
                        @remove-media="removeMiniatureImage"
                      />
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import store from '@/store'
import ModuleView from '../../components/ModuleView'
import Modal from '@/components/shared/Modal'
import Input from '../../components/form/inputs/Input'
import Select from '../../components/form/select/Select'
import RichTextEditor from '@/components/form/RichTextEditor'
import TinyMceConfig from '@/services/tinymce/TinyMceConfig'
import TinyMceConfigVLMSettingsSecondary from '@/model/TinyMceConfigVLMSettingsSecondary'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import { MEDIA_USAGE_TYPE_RUBRIC_HERO, MEDIA_USAGE_TYPE_RUBRIC_MINIATURE } from '@/model/ValueObject/MediaUsageTypes'
import IconCheck from '@/assets/img/svg/check.svg?inline'
import { integer, maxLength, minLength, minValue, required } from 'vuelidate/lib/validators'
import RubricModel from '../../model/Rubric'
import ButtonDelete from '../../components/shared/ButtonDelete'
import ButtonClose from '../../components/shared/ButtonClose'
import MultiSelect from '@/components/form/select/MultiSelect'
import NotifyService from '../../services/NotifyService'
import CoreApi from '@/api/core'
import Checkbox from '../../components/form/Checkbox'
import PermissionService from '@/services/PermissionService'
import { mapGetters } from 'vuex'

const IS_VLM = store.getters.vlm

export default {
  name: 'RubricNewView',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      isEdit: false,
      iptcCategories: [],
      productsForRubric: [],
      rubric: this._.cloneDeep(RubricModel),
      mediaUsageTypes: { hero: MEDIA_USAGE_TYPE_RUBRIC_HERO, miniature: MEDIA_USAGE_TYPE_RUBRIC_MINIATURE },
      rubricsBySite: [],
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_RUBRIC_PERMISSIONS,
      showVirtualSwitchModal: false
    }
  },
  computed: {
    ...mapGetters({
      edonProducts: 'edonProduct/list',
      getByIds: 'edonProduct/getByIds'
    }),
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isDeleteButtonVisible () {
      return this.rubric?.id && this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    },
    isVirtualizeButtonVisible () {
      const site = this.sites.find(site => site.id === this.originalSiteId)
      return this.vlm && this.rubric.id && site?.isMasterSite && this.hasPermission(this.requiredPermissions.virtualizeButton)
    },
    sites () {
      return this.$store.getters['site/allEnabledSorted']()
    },
    rubrics () {
      return this.$store.getters['rubric/list']
    },
    conflictData () {
      return this.$store.getters['rubric/conflictData']
    },
    originalSiteId () {
      return this.$store.getters['rubric/originalSiteId']
    },
    vlm () {
      return IS_VLM
    },
    productLabel () {
      const label = this.$t('rubric.product')
      return IS_VLM ? label : `${label}*`
    },
    richTextEditorConfig () {
      if (this.vlm) {
        return {
          ...TinyMceConfig.getConfig(),
          ...TinyMceConfigVLMSettingsSecondary
        }
      } else {
        return {
          ...TinyMceConfig.getConfig(),
          height: 200,
          autoresize_min_height: 200,
          style_formats: [
            {
              title: 'Headers',
              items: [
                { title: 'Header 2', format: 'h2' },
                { title: 'Header 3', format: 'h3' },
                { title: 'Paragraph', format: 'p' }
              ]
            },
            {
              title: 'Inline',
              items: [
                { title: 'Bold', icon: 'bold', format: 'bold' },
                { title: 'Italic', icon: 'italic', format: 'italic' },
                { title: 'Underline', icon: 'underline', format: 'underline' },
                { title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough' },
                { title: 'Superscript', icon: 'superscript', format: 'superscript' },
                { title: 'Subscript', icon: 'subscript', format: 'subscript' }
              ]
            }
          ],
          toolbar: [
            'undo redo | cut copy paste selectall | styleselect | rubric_headline_2 | custom_headline_2 custom_headline_3 | bold italic underline | custom_button_unordered_list numlist | link | fullscreen'
          ]
        }
      }
    }
  },
  validations: {
    rubric: {
      product: IS_VLM ? {} : {},
      dataHubProductsIds: {
        required,
        arrayMinLength (value) {
          return Array.isArray(value) && value.length >= 1
        }
      },
      site: {
        required
      },
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
      slug: {
        minLength: minLength(1),
        maxLength: maxLength(255)
      },
      textForSlug: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
      meta: {
        title: {
          maxLength: maxLength(255)
        },
        description: {
          maxLength: maxLength(255)
        },
        keywords: {
          maxLength: maxLength(255)
        }
      },
      setting: {
        advertCategory: {
          maxLength: maxLength(255)
        },
        advertSection: {
          maxLength: maxLength(255)
        },
        chartbeatSection: {
          maxLength: maxLength(255)
        },
        gemiusId: {
          maxLength: maxLength(80)
        }
      },
      sorting: {
        integer,
        minValue: minValue(0)
      },
      sportTable: IS_VLM ? {} : {
        seasonId: {
          integer,
          minValue: minValue(0)
        },
        activeTab: {
          integer,
          minValue: minValue(1)
        }
      }
    }
  },
  components: {
    MultiSelect,
    appModuleView: ModuleView,
    appInput: Input,
    appSelect: Select,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appCheckbox: Checkbox,
    RichTextEditor,
    Modal,
    ModuleFormPhotoBox,
    IconCheck
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    getEdonProducts () {
      this.$store.dispatch('edonProduct/fetchAll')
        .then(() => {
          if (this.rubric.dataHubProductsIds.length) {
            // Conversion of IDs from rubrics to products
            this.productsForRubric = this.getByIds(this.rubric.dataHubProductsIds)
          }
        })
        .catch(error => {
          console.error('Error loading products: ', error)
        })
    },
    async loadRubricsBySite () {
      await this.$store.dispatch('rubric/fetchRubricsBySiteId', this.rubric.site)
      this.rubricsBySite = this.$store.state.rubric.all.filter(item => item.site === this.rubric.site)
    },
    async toggleVirtual () {
      console.log('spustam toggle virtual')
      try {
        await this.$store.dispatch('rubric/toggleVirtual', this.rubric)
        if (this.$store.getters['rubric/error'] === null) {
          if (this.conflictData && this.conflictData.length > 0) {
            const rubricsInError = this.conflictData.reduce((acc, rubricInError) => {
              const siteTitle = this.$store.getters['site/getTitleById'](rubricInError.siteId)
              const message = `<li>${this.$t('rubric.site')}: <b>${siteTitle}</b>, ${this.$t('rubric.rubricId')}: <b>${rubricInError.rubricId}</b>, ${this.$t('rubric.rubricTitle')}: <b>${rubricInError.title}</b></li>`
              return `${acc}${message}`
            }, '')

            const formattedMessage = `<ul>${rubricsInError}</ul>`
            NotifyService.setErrorMessage(`<b>${this.$t('rubric.conflictData')}</b>`, formattedMessage, 10000)
          } else {
            await this.getRubric()
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          }
        } else {
          NotifyService.setErrorMessage(this.$store.getters['rubric/error'])
        }
        this.toggleVirtualSwitchModal('close')
      } catch (error) {
        console.error(error)
      }
    },
    toggleVirtualSwitchModal (state) {
      if (state === 'close') {
        this.showVirtualSwitchModal = false
      }
      if (state === 'open') {
        this.showVirtualSwitchModal = true
      }
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }

      if (this.vlm) {
        delete this.rubric.sportTable
      }

      this.$store.dispatch('rubric/create', this.rubric)
        .then(() => {
          if (this.$store.getters['rubric/error'] === null) {
            if (this.conflictData && this.conflictData.length > 0) {
              const rubricsInError = this.conflictData.reduce((acc, rubricInError) => {
                const siteTitle = this.$store.getters['site/getTitleById'](rubricInError.siteId)
                const message = `<li>${this.$t('rubric.site')}: <b>${siteTitle}</b>, ${this.$t('rubric.rubricId')}: <b>${rubricInError.rubricId}</b>, ${this.$t('rubric.rubricTitle')}: <b>${rubricInError.title}</b></li>`
                return `${acc}${message}`
              }, '')

              const formattedMessage = `<ul>${rubricsInError}</ul>`
              NotifyService.setErrorMessage(`<b>${this.$t('rubric.conflictData')}</b>`, formattedMessage, 10000)
            } else {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.rubric = this.$store.getters['rubric/detail']
              this.$router.push('/rubric/' + this.rubric.id + '/edit')
            }
          } else {
            NotifyService.setErrorMessage(this.$store.getters['rubric/error'])
          }
        })
        .catch(error => console.error(error))
    },
    deleteRubric () {
      this.$store.dispatch('rubric/deleteRecord', this.rubric)
        .then(() => {
          if (this.$store.getters['rubric/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/rubric')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['rubric/error'])
          }
        })
        .catch(error => console.error(error))
    },
    getSites () {
      this.$store.dispatch('site/fetchAll')
        .then(() => {
          this.dataLoaded = true
        })
    },
    async getRubric () {
      await this.$store.dispatch('rubric/fetchOne', this.$route.params.id)
        .then(() => {
          this.rubric = this.$store.getters['rubric/detail']
        })
    },
    setHeroImage (medias) {
      this.rubric.heroImage = medias[0]
      this.rubric.heroImageId = medias[0].id
    },
    removeHeroImage () {
      this.rubric.heroImage = {}
      this.rubric.heroImageId = null
    },
    setMiniatureImage (medias) {
      this.rubric.miniatureImage = medias[0]
      this.rubric.miniatureImageId = medias[0].id
    },
    removeMiniatureImage () {
      this.rubric.miniatureImage = {}
      this.rubric.miniatureImageId = null
    },
    getCategories () {
      CoreApi().get('/geneeaCategory?limit=300')
        .then(response => { this.iptcCategories = response.data.data })
        .catch(error => console.error(error))
    }
  },
  mounted () {
    this.loadRubricsBySite()
    this.getSites()
    this.getEdonProducts()
    if (IS_VLM) {
      this.getCategories()
    }
  },
  watch: {
    rubric: {
      deep: true,
      handler: function () {
        this.loadRubricsBySite()
      }
    },
    productsForRubric (newVal) {
      this.rubric.dataHubProductsIds = newVal?.map(product => product.id) ?? []
    },
    'rubric.site': function (newSite, oldSite) {
      if (this.rubric && newSite && oldSite) {
        if (newSite !== oldSite) {
          this.rubric.parent = null
        }
      }
    },
    'rubric.parent': function (newParent, oldParent) {
      if (this.rubric && newParent !== undefined && oldParent !== undefined) {
        if ((newParent !== oldParent) && newParent === 0) {
          this.rubric.parent = null
        }
      }
    }
  }
}
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
.title-text {
  margin-top: 1rem;
  color: #8A96AC;
  font-size: rem(14px);
}
.check-icon {
  @include size(18px);
  margin-left: 0.4rem;

  path:first-of-type {
    fill: #5AB963;
  }
  path:nth-of-type(2n){
    fill: #ffffff;
  }
}
</style>
